@import '../../../../scss/theme-bootstrap';

.finder-result,
.finder-result-match {
  max-width: 388px;
  margin: 0 15px 30px 15px;
  vertical-align: top;
  display: inline-block;
  text-align: $ldirection;
  .product-brief {
    &__name,
    &__price {
      padding: 3px 0;
      @include breakpoint($portrait-up) {
        padding: 8px 0;
      }
    }
    @include breakpoint($portrait-up) {
      &__detail {
        position: relative;
      }
      &__price {
        position: absolute;
        top: 0;
        #{$rdirection}: 0;
      }
      &__name {
        width: 85%;
      }
    }
  }
  .product-sku-price__value {
    @include basic-text-reset;
  }
}

.finder-result-match {
  max-width: 200px;
  .product-brief {
    @include breakpoint($portrait-up) {
      &__price {
        position: relative;
      }
    }
  }
}

.finder-result__label,
.finder-result-match__label {
  @include h2;
  margin: 0;
  text-align: center;
  position: relative;
  z-index: 1;
  top: 8px;
  @include breakpoint($portrait-up) {
    top: 10px;
  }
}

.finder-result__product-shade,
.finder-result-match__product-shade {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}